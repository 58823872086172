import { Transition, Dialog } from '@headlessui/react';
import { classnames } from '@ocenkatech/common/lib';
import { FC, Fragment, useRef, ReactNode } from 'react';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { ModalSize } from '../model/types';
import cls from './Modal.module.css';

interface ModalProps {
    open?: boolean;
    setOpen?: (arg: boolean) => void;
    title?: string;
    size?: ModalSize;
    isBtnClose?: boolean;
    actionSuccess?: ReactNode;
    children?: ReactNode;
}

export const Modal: FC<ModalProps> = ({
    open = false,
    setOpen = () => {},
    title,
    size = 'md',
    isBtnClose = true,
    actionSuccess,
    children,
}) => {
    const closeBtnRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-20"
                initialFocus={closeBtnRef}
                onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className={classnames(
                                    cls.dialogContent,
                                    cls['size-' + size],
                                )}>
                                {title && (
                                    <Typography type="h3" className="mb-4">
                                        {title}
                                    </Typography>
                                )}
                                <div>{children}</div>
                                <div className="mt-8 flex justify-end gap-3 empty:hidden sm:mt-6">
                                    {isBtnClose && (
                                        <Button
                                            theme="secondary"
                                            onClick={() => setOpen(false)}
                                            ref={closeBtnRef}>
                                            Закрыть
                                        </Button>
                                    )}
                                    {actionSuccess}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
