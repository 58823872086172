import {
    AUTH_ATTEMPTS_KEY,
    REQUEST_ATTEMPTS_KEY,
    USER_PHONE_NUMBER_KEY,
} from '@ocenkatech/common/const';
import {
    saveInStorage,
    inputMasks,
    isErrorDataMessage,
} from '@ocenkatech/common/lib';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from 'shared/lib/react/hooks';
import { useHandleErrors } from 'shared/lib/react/useHandleErrors';
import { Button } from 'shared/ui/Button';
import { Form } from 'shared/ui/Form';
import { Input } from 'shared/ui/Input';
import { LinkApp } from 'shared/ui/LinkApp';
import { usePopup } from 'shared/ui/Popup';
import { useSmsSendMutation } from '../../api/auth';
import { ErrorAuthSms } from '../../model/const';
import { authActions } from '../../model/slice/authSlice';

interface LoginFormProps {
    isLoading?: boolean;
}

export type LoginProps = {
    phoneNumber: string;
};

export const LoginForm: FC<LoginFormProps> = () => {
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm<LoginProps>();
    const [smsSend, { isLoading, error }] = useSmsSendMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { openPopup } = usePopup({ title: 'Произошла ошибка при входе' });

    const onLogin: SubmitHandler<LoginProps> = useCallback(
        async (data) => {
            try {
                const formattedPhone = data.phoneNumber;
                const response = await smsSend({
                    phoneNumber: formattedPhone,
                }).unwrap();

                if (response) {
                    dispatch(
                        authActions.setAuthData({
                            ...response,
                            phoneNumber: formattedPhone,
                        }),
                    );
                    saveInStorage(
                        [
                            USER_PHONE_NUMBER_KEY,
                            AUTH_ATTEMPTS_KEY,
                            REQUEST_ATTEMPTS_KEY,
                        ],
                        [
                            formattedPhone,
                            response.authAttempts.toString(),
                            response.requestAttempts.toString(),
                        ],
                    );

                    if (response.status === 'NO_ERROR') {
                        navigate('/login?confirmCode=true', {
                            state: { ...location.state },
                        });
                    } else {
                        openPopup({ title: ErrorAuthSms[response.status] });
                    }
                }
            } catch (e) {
                if (isErrorDataMessage(e)) {
                    openPopup({
                        content: Object.values(e.data).join(', '),
                    });
                }
            }
        },
        [dispatch, location.state, navigate, openPopup, smsSend],
    );

    useHandleErrors<LoginProps>(error, setError, ['phoneNumber']);

    return (
        <Form onSubmit={handleSubmit(onLogin)}>
            <Input
                formKey={'phoneNumber'}
                label={'Телефон'}
                mask={inputMasks.phoneNumber}
                fieldClassName="mb-6"
                error={errors.phoneNumber}
                registerOptions={{ required: true }}
                register={register}
                inputProps={{
                    type: 'tel',
                }}
            />
            <Button theme="link" className="mr-auto">
                Забыли пароль?
            </Button>
            <div className="mt-6 flex flex-col gap-6">
                <Button
                    type="submit"
                    size="lg"
                    isLoading={isLoading}
                    className="uppercase">
                    Далее
                </Button>
                <p className="mb-0 text-center">
                    У вас еще нет аккаунта?{' '}
                    <LinkApp to="/registration">Зарегистрироваться</LinkApp>
                </p>
            </div>
        </Form>
    );
};
